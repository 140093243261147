import React from 'react';
import Layout from '../components/layout/layout';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import '../styles/blogPost.scss';

const BlogPost = ({ location, pageContext: data }) => {
  return <Layout location={location}>
    <div className="BlogPost">
      <div className="BlogPost__content">
        <img src={data.image.file.url ? data.image.file.url : 'https://via.placeholder.com/350x150'} />
        <h1 className="BlogPost__title">{data.name}</h1>

        <div className="BlogPost__html">
          {renderRichText(data.content)}
        </div>
      </div>
    </div>
  </Layout>
}

export default BlogPost
